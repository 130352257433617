<template>
  <div>
    <v-row>
      <v-col cols="12">
        <b>Store:</b><br>
        {{ showErwBerechnungen }}
        <hr>
        <b>Ref:</b>
        {{ showBerechnungen }}
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import BaseCalls from '@/services/BaseCalls';
import { useBerechnungStore } from '@/stores/berechnung';

const berechnungStore = useBerechnungStore();

const showErwBerechnungen = ref([]);
const showBerechnungen = computed(() => berechnungStore.showBerechnungen);

async function getShowBerechnungen() {
  const berSelect = { action: 'getShowBerechnungen' };

  try {
    const resp = await BaseCalls.postBerechnung(berSelect);
    if (resp.data && resp.data.berechnungen) {
      berechnungStore.setShowBerechnungen(resp.data.berechnungen);
      console.log('showBerechnungen');
    }
  } catch (err) {
    console.log('Error fetching calculations');
  }
}

watch(
  () => berechnungStore.showBerechnungen,
  (newVal) => {
    if (
      newVal && newVal.length > 0
    ) {
      console.log('showErwBerechnungen Watcher');
      showErwBerechnungen.value = berechnungStore.getFilteredShowBerechnungen(1);
    }
  },
  { immediate: true }
);

onMounted(() => {
  getShowBerechnungen();
});


</script>
