<template>
  <div>
    <span v-if="showMobile">
      <div :class="wwClassMobile">
        <TooltipHelper :toolkey="toolkey">
          <template v-slot:toolTipText>
            <h4 class="text-white">{{ ww }}%</h4>
          </template>
        </TooltipHelper>
      </div>
    </span>
    <span v-if="!showMobile">
      <div :class="wwClass">
        <h2 class="text-white">#{{ tarif.rank }}</h2>
      </div>
      <div class="pl-2 py-2 blackground">
        <TooltipHelper :toolkey="toolkey">
          <template v-slot:toolTipText>
            <h3 class="text-white">{{ ww }}%</h3>
          </template>
        </TooltipHelper>
      </div>
      <div>
        <v-icon
          v-if="tarif.empfehlung_type > 0"
          class="text-green pa-6"
          style="font-size: 36px"
        >
          mdi-star
        </v-icon>
      </div>
    </span>
  </div>
</template>

<script setup>
  import { ref, computed, onMounted, watch, defineProps } from "vue";
  import TooltipHelper from "@/components/base/TooltipHelper.vue";
  //   import { useBerechnungStore } from "@/stores/berechnung";
  //   import { useSharedDataStore } from "@/stores/sharedData";
  //   const sharedDataStore = useSharedDataStore();
  //   const berechnungStore = useBerechnungStore();

  const props = defineProps({
    showMobile: {
      type: Boolean,
    },
    tarif: {
      type: Object,
      default: () => ({}),
    },
    berechnung: {
      type: Object,
      default: () => ({}),
    },
  });

  const wwClassMobile = ref(false);
  const wwClass = ref(false);

  const toolkey = computed(() => {
    if (
      props.berechnung.ber_segment <= 1 &&
      props.berechnung.ber_algo_view != 2
    ) {
      return "tarif_waizmannwert";
    } else if (
      props.berechnung.ber_segment <= 1 &&
      props.berechnung.ber_algo_view == 2
    ) {
      return "tarif_kwert_gesamt";
    } else if (props.berechnung.ber_segment == 2) {
      return "bri_ww";
    } else if (props.berechnung.ber_segment == 3) {
      return "hp_gesamterstattung_2_4";
    } else if (props.berechnung.ber_segment == 4) {
      return "kh_ww";
    }
    return 0; // Standardfall, wenn keine anderen Bedingungen erfüllt sind
  });
  const ww = computed(() => {
    if (
      props.berechnung.ber_segment <= 1 &&
      props.berechnung.ber_algo_view != 2
    ) {
      return Math.round(props.tarif.tarif_waizmannwert || 0);
    } else if (
      props.berechnung.ber_segment <= 1 &&
      props.berechnung.ber_algo_view == 2
    ) {
      return Math.round(props.tarif.tarif_kwert_gesamt || 0);
    } else if (props.berechnung.ber_segment == 2) {
      return Math.round(props.tarif.bri_ww || 0);
    } else if (props.berechnung.ber_segment == 3) {
      return Math.round(props.tarif.hp_gesamterstattung_2_4 || 0);
    } else if (props.berechnung.ber_segment == 4) {
      return Math.round(props.tarif.kh_ww || 0);
    }
    return 0; // Standardfall, wenn keine anderen Bedingungen erfüllt sind
  });

  // Funktion zum Aktualisieren von wwClassMobile und wwClass
  const updateWwClass = () => {
    if (props.tarif.empfehlung_type > 0) {
      wwClassMobile.value = "pl-1 py-2 " + props.berechnung.empRankBest + " ";
      wwClass.value = "pl-2 py-2 " + props.berechnung.empRankBest + " ";
    } else {
      wwClassMobile.value = "pl-1 py-2 " + props.berechnung.empRankBase + " ";
      wwClass.value = "pl-2 py-2 " + props.berechnung.empRankBase + " ";
    }
  };

  // Aufrufen der Funktion beim Mounten des Components
  onMounted(() => {
    updateWwClass();
  });

  // Watcher für Änderungen an props.berechnung.ber_id
  watch(
    () => props.berechnung.ber_id,
    () => {
      updateWwClass();
    }
  );

  console.log(props.tarif);
</script>
