<template>
  <div>
   <v-row class="my-12" style="" v-if="sharedDataStore.isDesktop()">
       <v-col cols="12" md="3" class="pr-6"  style="border-right: 2px solid #E5EDF4;text-align: right;">
        <span style="font-size:32px;font-weight: bold;">WaizmannTabelle </span><br>
        <span style="font-size:24px;">Nr. 1 bei Zahnärzten<br>seit 1995</span>
       </v-col>
       <v-col  cols="12" md="3" class="pl-6" style="border-right: 2px solid #E5EDF4; display: flex; flex-direction: column; justify-content: flex-end;">
             <v-icon class="text-blue" style="font-size: 56px; align-self: flex-start;">mdi-tooth</v-icon>
             <span style="align-self: flex-end;"><b>über 11000 Zahnärzte</b>  empfehlen die WaizmannTabelle</span>
       </v-col>
       <v-col cols="12" md="3" class="pl-6" style="border-right: 2px solid #E5EDF4; display: flex; flex-direction: column; justify-content: flex-end;">
             <v-icon class="text-blue" style="font-size: 56px; align-self: flex-start;">mdi-school</v-icon>
             <span style="align-self: flex-end;"><b>mathematisch basierte</b> , standardisierte Bewertungsmethode</span>
       </v-col>
       <v-col cols="12" md="3" class="pl-6" style="display: flex; flex-direction: column; justify-content: flex-end;">
             <v-icon class="text-blue" style="font-size: 56px; align-self: flex-start;">mdi-shield-alert-outline</v-icon>
             <span style="align-self: flex-end;"><b>Clearment Vertragsschutz</b><br> Absicherung vertraglicher Leistungen</span>
       </v-col>
     </v-row>
     <v-row v-if="!sharedDataStore.isDesktop()" >
      <v-col cols="12"><h1>
              WaizmannTabelle 2024 |
              <span class="text-blue">Nr. 1 bei Zahnärzten</span>
            </h1>

        Seit 1995 zu 100 % auf Zahnzusatzversicherungen spezialisiert.
           
              Der 1. Online-Vergleich (seit 1995) für Zahnzusatzversicherungen
              in Deutschland. In nur 1-2 Minuten wertet der Waizmann-Rechner
              über 200 getestete Zahntarife aus.<br><br>
              <v-btn  @click="startRechner('erwachsene')" color="blue" class="text-white nocaps mb-2" rounded="0">Jetzt Vergleich starten</v-btn>
      </v-col>
     </v-row>
  </div>
 </template>
 
 <script setup>
 import { useSharedDataStore } from "@/stores/sharedData";
 const sharedDataStore = useSharedDataStore();
 </script>
 