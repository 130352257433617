<template>
  <div>
    <v-row>
      <v-col cols="12"
        ><h2>Ihre "Zusatzversicherung"-Berechnungen</h2>
        Wenn Sie noch eine neue Berechnung starten wollen,
        <router-link
          @click="berechnungStore.closePop(berechnungStore.tarif)"
          to="/zusatzversicherung"
          >klicken Sie bitte hier...</router-link
        ><br />
        <br />
        <div v-for="b in berechnungen" :key="b.ber_id" class="my-2">
          <v-row>
            <v-col cols="12" md="7">
              <h3 :style="segmentStyle(b)">
                {{ b.showBerechnungName }} | {{ b.show_ber_geschlecht }},
                {{ b.show_ber_geburtstag }}
              </h3>
              <br />
              <v-table style="font-size: 15px">
                <tr v-if="b.ber_best_emp != ''" style="vertical-align: top" >
                  <td>
                    <b>{{ b.ber_best_emp }}</b>

                    <br />
                    Empfehlung "beste Leistung"
                    <v-icon class="text-green" style="font-size: 18px">
                      mdi-star </v-icon
                    ><br />
                    <router-link
                      @click="berechnungStore.closePopApp"
                      :to="`/angebot/kostenlos/${b.ber_beste_leistung_tarif_id}/${b.ber_identity_token}`"
                      ><b>jetzt Antrag & Infopaket anfordern</b></router-link
                    >
                    <br />
                    <br />
                  </td>
                  <td>{{ b.ber_best_emp_preis }} €</td>
                </tr>
                <tr v-if="b.ber_alt_emp != ''" style="vertical-align: top">
                  <td>
                    <span v-if="b.ber_kunden_id == 0">
                      <b>{{ b.ber_alt_emp }}</b>
                      <br />
                      Sehr gute Alternative
                      <v-icon class="text-green" style="font-size: 18px">
                        mdi-star </v-icon
                      ><br />
                      <router-link
                        @click="berechnungStore.closePopApp"
                        :to="`/angebot/kostenlos/${b.ber_beste_alternative_tarif_id}/${b.ber_identity_token}`"
                        ><b>jetzt Antrag & Infopaket anfordern</b></router-link
                      ><br />
                    </span>
                    <span v-if="b.ber_kunden_id != 0">
                      <h3 class="text-green">
                        Sie haben für diese Berechnung ein Antrag & Infopaket
                        angefordert
                      </h3>
                    </span>
                  </td>
                  <td>{{ b.ber_alt_emp_preis }} €</td>
                </tr>
                <tr>
                  <td colspan="2">
                    <br />
                    <span v-if="b.ber_kunden_id == 0">
                      <router-link
                        @click="berechnungStore.closePopApp"
                        :to="`/vergleich/ergebnis/${b.ber_identity_token}`"
                        ><v-btn
                          class="button_caps"
                          variant="outlined"
                          :color="sharedDataStore.showBtnColoBer(b.ber_segment)"
                          >diese Berechnung öffnen</v-btn
                        ></router-link
                      >
                    </span>
                  </td>
                </tr>
              </v-table>
            </v-col>
            <v-col cols="12" md="5">
              <ul>
                <li
                  v-for="(item, index) in b.berlog"
                  v-bind:key="index"
                  v-html="nl2br(item.value)"
                ></li>
              </ul>
            </v-col>
          </v-row>
          <v-divider :thickness="4" color="blue" class="my-4"></v-divider>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
  import { onMounted, ref } from "vue";
  import BaseCalls from "@/services/BaseCalls";
  import { useBerechnungStore } from "@/stores/berechnung";
  // import { useSharedDataStore } from "@/stores/sharedData";
  // const sharedDataStore = useSharedDataStore();
  import { useLoaderStore } from "@/stores/loaderStore";
  import { useSharedDataStore } from "@/stores/sharedData";
  const sharedDataStore = useSharedDataStore();
  const loaderStore = useLoaderStore();
  import { useNl2Br } from "@/composable/useNl2Br";
  const { nl2br } = useNl2Br();
  const berechnungStore = useBerechnungStore();

  const berechnungen = ref(false);

  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }
  async function getBerechnungen() {
    let berSelect = {};
    berSelect.action = "getAllUserBerechnungen";
    console.log("getAllUserBerechnungen");
    setLoading(true);
    try {
      const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.berechnungen) {
        berechnungen.value = resp.data.berechnungen;
        setLoading(false);
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }

  const segmentStyle = (berechnung) => {
    return { color: `${berechnung.segmentColor || "#305fa5"} !important` };
  };

  onMounted(() => {
    getBerechnungen();
  });
</script>
