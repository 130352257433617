<template>
  <div>
    <!-- Formular anzeigen, wenn props.berechnung nicht vorhanden ist -->
  
    <div v-if="!berechnung || berechnungOnSite">
      <v-row>
        <v-col cols="9">
      <v-card elevation="1" class="pa-4 mb-8">
        <h2 class="mb-4">Zahnzusatzversicherung für privat Krankenversicherte - jetzt berechnen</h2>
      <v-form ref="form" @submit.prevent="handleSubmit">
        <v-row>
          <v-col cols="2">
            <v-text-field
              v-model="userInput.alter"
              label="Ihr Alter"
              type="number"
              min="0"
              required
              :rules="[v => !!v || 'Bitte Ihr Alter eingeben']"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="userInput.ber_pkv_zahn_erstattung"
              label="Erstattungssatz Ihrer PKV für zahnärztliche Leistungen"
              type="number"
              min="0"
              max="90"
              required
              :rules="[v => !!v || 'Bitte Erstattungssatz eingeben']"
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="pt-6">
            <v-btn
              color="blue"
              elevated
              tile
              rounded="0"
              type="submit"
              class="button_caps"
              tonal
            >
              <span>Berechnung starten {{ showError }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-alert class="mt-4" color="red" v-if="showError" type="error"><h2 class="text-white">Bitte füllen Sie alle Felder aus</h2></v-alert>
      </v-form>
    </v-card>
    </v-col>
      </v-row>
    </div>

    <!-- Inhalt anzeigen, wenn berechnung vorhanden ist -->
    <div v-if="berechnung">
      <v-row>
        <v-col cols="9">
          <h2>Zahnzusatzversicherung für privat Krankenversicherte</h2>
          <h1>
            Ihr Ergebnis:
            <span class="text-blue">
              Eintrittsalter:
              {{ berechnung.ber_alter }} Jahre
            </span>
          </h1>
          <ul>
            <li>Eintrittsalter: <b>{{ berechnung.ber_alter }} Jahre</b></li>
            <li>Erstattungssatz Ihrer PKV: <b>{{ berechnung.ber_pkv_zahn_erstattung }} %</b></li>
            <li>Die Würzburger Versicherung bietet die derzeit einzigste Zahnzusatzversicherung für private Krankenversicherte.</li>
          </ul>
          <!-- Div für Satz 25 -->
          <div
            v-if="berechnung.ber_pkv_zahn_erstattung >= 25"
            class="my-8 pb-4"
            style="border-left: 4px solid #f2f2f2; border-bottom: 4px solid #f2f2f2;"
          >
            <v-row>
              <v-col cols="2">
                <div class="pl-2 py-2 greenground">
                  <h2 class="text-white">{{ coverageAvailable[25] }} %</h2>
                </div>
                <div class="pl-2 py-2">Erstattung inkl. PKV</div>
              </v-col>
              <v-col cols="6">
                <h3 style="font-size: 28px; font-weight: bold">
                  Würzburger DentOptimal25
                </h3>

                <v-row>
                  <v-col cols="3">Erstattung</v-col>
                  <v-col cols="9">
                    <b>25 %</b> auf den Rechnungsbetrag
                    <br />
                    Erstattung PKV {{ berechnung.ber_pkv_zahn_erstattung }} % + DentOptimal25 25 % = <b class="text-green">{{ coverageAvailable[25] }} % Gesamterstattung</b>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">Leistungs-bereiche</v-col>
                  <v-col cols="9">
                    <ul>
                      <li>100 % Zahnersatz</li>
                      <li class="text-red">keine Zahnerhalt-Leistungen</li>
                   
                    </ul>
                  </v-col>
                </v-row>
                <!-- Updated Anfängliche Begrenzungen -->
                <v-row>
                  <v-col cols="3">Anfängliche Begrenzungen</v-col>
                  <v-col cols="9">
                    <ul>
                      <li>1. Jahr: 250 EUR</li>
                      <li>1.-2. Jahr: 500 EUR</li>
                      <li>1.-3. Jahr: 750 EUR</li>
                      <li>1.-4. Jahr: 1,000 EUR</li>
                      <li>ab dem 5. Jahr: <b class="text-green">unbegrenzt</b></li>
                    </ul>
                
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2">
                <h3 style="font-size: 28px; font-weight: bold">
                  {{ beitraege[25].toFixed(2) }} €
                </h3>
                Monatsbeitrag
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="10">
                <v-btn
                  color="blue"
                  outlined
                  tile
                  rounded="0"
                  href="https://rechner.wuerzburger.com/Angebot/KV878DentOptimal/DentOptimal.aspx?partnerid=1-1-1899"
                  target="_blank"
                  class="button_caps"
                  tonal
                >
                  Jetzt Würzburger DentOptimal25 online beantragen
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <!-- Div für Satz 20 -->
          <div
            v-if="berechnung.ber_pkv_zahn_erstattung >= 20"
            class="my-8"
            style="border-left: 4px solid #f2f2f2; border-bottom: 4px solid #f2f2f2;"
          >
            <v-row>
              <v-col cols="2">
                <div class="pl-2 py-2 greenground">
                  <h2 class="text-white">{{ coverageAvailable[20] }} %</h2>
                </div>
                <div class="pl-2 py-2">Erstattung inkl. PKV</div>
              </v-col>
              <v-col cols="6">
                <h3 style="font-size: 28px; font-weight: bold">
                  Würzburger DentOptimal20
                </h3>
                <v-row>
                  <v-col cols="3">Erstattung</v-col>
                  <v-col cols="9">
                    <b>20 %</b> auf den Rechnungsbetrag
                    <br />
                    Erstattung PKV {{ berechnung.ber_pkv_zahn_erstattung }} % + DentOptimal20 20 % = <b class="text-green">{{ coverageAvailable[20] }} % Gesamterstattung</b>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">Leistungs-bereiche</v-col>
                  <v-col cols="9">
                    <ul>
                      <li>100 % Zahnersatz</li>
                      <li class="text-red">keine Zahnerhalt-Leistungen</li>
                   
                    </ul>
                  </v-col>
                </v-row>
                <!-- Updated Anfängliche Begrenzungen -->
                <v-row>
                  <v-col cols="3">Anfängliche Begrenzungen</v-col>
                  <v-col cols="9">
                    <ul>
                      <li>1. Jahr: 200 EUR</li>
                      <li>1.-2. Jahr: 400 EUR</li>
                      <li>1.-3. Jahr: 600 EUR</li>
                      <li>1.-4. Jahr: 800 EUR</li>
                      <li>ab dem 5. Jahr: <b class="text-green">unbegrenzt</b></li>
                    </ul>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2">
                <h3 style="font-size: 28px; font-weight: bold">
                  {{ beitraege[20].toFixed(2) }} €
                </h3>
                Monatsbeitrag
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="10">
                <v-btn
                  color="blue"
                  outlined
                  tile
                  rounded="0"
                  href="https://rechner.wuerzburger.com/Angebot/KV878DentOptimal/DentOptimal.aspx?partnerid=1-1-1899"
                  target="_blank"
                  class="button_caps"
                  tonal
                >
                  Jetzt Würzburger DentOptimal20 online beantragen
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <!-- Div für Satz 15 -->
          <div
            v-if="berechnung.ber_pkv_zahn_erstattung >= 15"
            class="my-8"
            style="border-left: 4px solid #f2f2f2; border-bottom: 4px solid #f2f2f2;"
          >
            <v-row>
              <v-col cols="2">
                <div class="pl-2 py-2 greenground">
                  <h2 class="text-white">{{ coverageAvailable[15] }} %</h2>
                </div>
                <div class="pl-2 py-2">Erstattung inkl. PKV</div>
              </v-col>
              <v-col cols="6">
                <h3 style="font-size: 28px; font-weight: bold">
                  Würzburger DentOptimal15
                </h3>
                <v-row>
                  <v-col cols="3">Erstattung</v-col>
                  <v-col cols="9">
                    <b>15 %</b> auf den Rechnungsbetrag
                    <br />
                    Erstattung PKV {{ berechnung.ber_pkv_zahn_erstattung }} % + DentOptimal15 15 % = <b class="text-green">{{ coverageAvailable[15] }} % Gesamterstattung</b>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">Leistungs-bereiche</v-col>
                  <v-col cols="9">
                    <ul>
                      <li>100 % Zahnersatz</li>
                      <li class="text-red">keine Zahnerhalt-Leistungen</li>
                   
                    </ul>
                  </v-col>
                </v-row>

                <!-- Updated Anfängliche Begrenzungen -->
                <v-row>
                  <v-col cols="3">Anfängliche Begrenzungen</v-col>
                  <v-col cols="9">
                    <ul>
                      <li>1. Jahr: 150 EUR</li>
                      <li>1.-2. Jahr: 300 EUR</li>
                      <li>1.-3. Jahr: 450 EUR</li>
                      <li>1.-4. Jahr: 600 EUR</li>
                      <li>ab dem 5. Jahr: <b class="text-green">unbegrenzt</b></li>
                    </ul>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2">
                <h3 style="font-size: 28px; font-weight: bold">
                  {{ beitraege[15].toFixed(2) }} €
                </h3>
                Monatsbeitrag
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="10">
                <v-btn
                  color="blue"
                  outlined
                  tile
                  rounded="0"
                  href="https://rechner.wuerzburger.com/Angebot/KV878DentOptimal/DentOptimal.aspx?partnerid=1-1-1899"
                  target="_blank"
                  class="button_caps"
                  tonal
                >
                  Jetzt Würzburger DentOptimal15 online beantragen
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <!-- Div für Satz 10 -->
          <div
            v-if="berechnung.ber_pkv_zahn_erstattung >= 10"
            class="my-8"
            style="border-left: 4px solid #f2f2f2; border-bottom: 4px solid #f2f2f2;"
          >
            <v-row>
              <v-col cols="2">
                <div class="pl-2 py-2 greenground">
                  <h2 class="text-white">{{ coverageAvailable[10] }} %</h2>
                </div>
                <div class="pl-2 py-2">Erstattung inkl. PKV</div>
              </v-col>
              <v-col cols="6">
                <h3 style="font-size: 28px; font-weight: bold">
                  Würzburger DentOptimal10
                </h3>
                <v-row>
                  <v-col cols="3">Erstattung</v-col>
                  <v-col cols="9">
                    <b>10 %</b> auf den Rechnungsbetrag
                    <br />
                    Erstattung PKV {{ berechnung.ber_pkv_zahn_erstattung }} % + DentOptimal10 10 % = <b class="text-green">{{ coverageAvailable[10] }} % Gesamterstattung</b>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">Leistungs-bereiche</v-col>
                  <v-col cols="9">
                    <ul>
                      <li>100 % Zahnersatz</li>
                      <li class="text-red">keine Zahnerhalt-Leistungen</li>
                   
                    </ul>
                  </v-col>
                </v-row>
                <!-- Updated Anfängliche Begrenzungen -->
                <v-row>
                  <v-col cols="3">Anfängliche Begrenzungen</v-col>
                  <v-col cols="9">
                    <ul>
                      <li>1. Jahr: 100 EUR</li>
                      <li>1.-2. Jahr: 200 EUR</li>
                      <li>1.-3. Jahr: 300 EUR</li>
                      <li>1.-4. Jahr: 400 EUR</li>
                      <li>ab dem 5. Jahr: <b class="text-green">unbegrenzt</b></li>
                    </ul>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2">
                <h3 style="font-size: 28px; font-weight: bold">
                  {{ beitraege[10].toFixed(2) }} €
                </h3>
                Monatsbeitrag
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="10">
                <v-btn
                  color="blue"
                  outlined
                  tile
                  rounded="0"
                  href="https://rechner.wuerzburger.com/Angebot/KV878DentOptimal/DentOptimal.aspx?partnerid=1-1-1899"
                  target="_blank"
                  class="button_caps"
                  tonal
                >
                  Jetzt Würzburger DentOptimal10 online beantragen
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script setup>
import { defineProps, reactive, ref } from "vue";

const props = defineProps({
  berechnung: {
    type: Object,
    default: null,
  },
});

// Reaktive Variablen für Benutzerinput
const userInput = reactive({
  alter: null,
  ber_pkv_zahn_erstattung: null,
});

// Referenz zum Formular
const form = ref(null);
const berechnungOnSite = ref(false);
const showError = ref(false);

// Lokale Berechnungsdaten initialisieren
const berechnung = ref(props.berechnung);
if (berechnung.value.ber_pkv_zahn_erstattung == 0) {
  berechnung.value.ber_pkv_zahn_erstattung = 50;
}
// Funktionen zum Berechnen der Beiträge und der verfügbaren Deckung
function getBeitrag(alter, satz) {


  const beitragTable = {
    10: [
      { minAge: 0, maxAge: 30, price: 3.6 },
      { minAge: 31, maxAge: 40, price: 6.5 },
      { minAge: 41, maxAge: 50, price: 10.0 },
      { minAge: 51, maxAge: 60, price: 15.5 },
      { minAge: 61, maxAge: 200, price: 22.5 },
    ],
    15: [
      { minAge: 0, maxAge: 30, price: 4.2 },
      { minAge: 31, maxAge: 40, price: 7.5 },
      { minAge: 41, maxAge: 50, price: 11.1 },
      { minAge: 51, maxAge: 60, price: 18.5 },
      { minAge: 61, maxAge: 200, price: 25.5 },
    ],
    20: [
      { minAge: 0, maxAge: 30, price: 5.5 },
      { minAge: 31, maxAge: 40, price: 8.5 },
      { minAge: 41, maxAge: 50, price: 12.5 },
      { minAge: 51, maxAge: 60, price: 19.8 },
      { minAge: 61, maxAge: 200, price: 27.5 },
    ],
    25: [
      { minAge: 0, maxAge: 30, price: 6.0 },
      { minAge: 31, maxAge: 40, price: 9.6 },
      { minAge: 41, maxAge: 50, price: 14.9 },
      { minAge: 51, maxAge: 60, price: 21.7 },
      { minAge: 61, maxAge: 200, price: 29.5 },
    ],
  };

  const satzTable = beitragTable[satz];
  if (!satzTable) {
    throw new Error("Ungültiger Satzwert");
  }

  for (const entry of satzTable) {
    if (alter >= entry.minAge && alter <= entry.maxAge) {
      return entry.price;
    }
  }
  throw new Error("Alter nicht im gültigen Bereich");
}

const satzValues = [25, 20, 15, 10];
const beitraege = reactive({});
const coverageAvailable = reactive({});

function calculateBeitraege() {
  satzValues.forEach((satz) => {
    beitraege[satz] = getBeitrag(berechnung.value.ber_alter, satz);
    coverageAvailable[satz] =
      berechnung.value.ber_pkv_zahn_erstattung + satz;

    // coverageAvailable[10] nie mehr als 100%
    if (coverageAvailable[satz] > 100) {
      coverageAvailable[satz] = 100;
    }
  });
}

// Funktion zum Handhaben des Formular-Submits
function handleSubmit() {
  showError.value = false;
  berechnungOnSite.value = true;
  if (!userInput.alter || !userInput.ber_pkv_zahn_erstattung) {
    console.log('showError');
    showError.value = true;
    return null;
  }
  if (form.value.validate()) {

    berechnung.value = {
      ber_alter: parseInt(userInput.alter),
      ber_pkv_zahn_erstattung: parseInt(userInput.ber_pkv_zahn_erstattung),
    };
    calculateBeitraege();
  }
}

// Initiale Berechnung ausführen, wenn berechnung vorhanden ist
if (berechnung.value) {
  calculateBeitraege();
}
</script>






