<template>
  <div>
   <v-row class="my-12">
       <v-col  v-if="orderBox == 'erwachsen'" cols="12" md="3" class="pr-6"  style="border-left: 4px solid #E5EDF4;text-align: right;">
        
        <span style="font-size:24px;">Jetzt mehr als 200 Zahntarife <a>vergleichen</a>.</span><br>
        <span style="font-size:18px;">In nur 1 Minute zum Ergebnis</span>
       </v-col>
       <v-col  v-if="orderBox == 'erwachsen'"  @click="startRechner('erwachsene')" cols="12" md="3" class="pl-6" style="background-color: #E5EDF4;border-right: 2px solid #E5EDF4; display: flex; flex-direction: column; justify-content: flex-end;cursor: pointer;">
            <span style="align-self: flex-end;" class="mb-2"><b>Zahnzusatzversicherung  Erwachsene</b><br>Schwerpunkt Zahnersatz & Zahnerhalt</span>
            <v-btn color="blue" class="text-white nocaps" rounded="0">Jetzt Vergleich starten <v-icon class="ml-2" style="font-size: 24px;">mdi-cursor-default-click</v-icon></v-btn>
       </v-col>
       <v-col v-if="orderBox == 'kind'" @click="startRechner('kind')"  cols="12" md="3" class="pl-6" style="background-color: #E5EDF4;border-right: 2px solid #E5EDF4; display: flex; flex-direction: column; justify-content: flex-end;cursor: pointer;">
            <span style="align-self: flex-end;" class="mb-2"><b>Zahnzusatzversicherung  Kinder</b><br>Schwerpunkt Kieferfehlstellung & Kinderleistungen</span>
            <v-btn color="#fecf0c" class="text-white nocaps" rounded="0">Jetzt KFO-Vergleich starten <v-icon class="ml-2" style="font-size: 24px;">mdi-cursor-default-click</v-icon></v-btn>
           
       </v-col>

       <v-col v-if="orderBox == 'plus'" @click="startRechner('60plus')"  cols="12" md="3" class="pl-6" style="display: flex;background-color: #E5EDF4; flex-direction: column; justify-content: flex-end;cursor: pointer;">
            <span style="align-self: flex-end;" class="mb-2"><b>Zahnzusatzversicherung  60Plus</b><br>Schwerpunkt Zahnerhalt & Prothetikleistungen</span>
            <v-btn color="#513b83" class="text-white nocaps" rounded="0">Jetzt 60+-Vergleich starten <v-icon class="ml-2" style="font-size: 24px;">mdi-cursor-default-click</v-icon></v-btn>
           
       </v-col>

       <v-col  v-if="orderBox != 'erwachsen'"  @click="startRechner('erwachsene')" cols="12" md="3" class="pl-6" style="border-right: 2px solid #E5EDF4; display: flex; flex-direction: column; justify-content: flex-end;cursor: pointer;">
            <span style="align-self: flex-end;" class="mb-2"><b>Zahnzusatzversicherung  Erwachsene</b><br>Schwerpunkt Zahnersatz & Zahnerhalt</span>
            <v-btn color="blue" class="text-white nocaps" rounded="0">Jetzt Vergleich starten <v-icon class="ml-2" style="font-size: 24px;">mdi-cursor-default-click</v-icon></v-btn>
       </v-col>

       <v-col  v-if="orderBox != 'kind'"  @click="startRechner('kind')"  cols="12" md="3" class="pl-6" style="border-right: 2px solid #E5EDF4; display: flex; flex-direction: column; justify-content: flex-end;cursor: pointer;">
            <span style="align-self: flex-end;" class="mb-2"><b>Zahnzusatzversicherung  Kinder</b><br>Schwerpunkt Kieferfehlstellung & Kinderleistungen</span>
            <v-btn color="#fecf0c" class="text-white nocaps" rounded="0">Jetzt KFO-Vergleich starten <v-icon class="ml-2" style="font-size: 24px;">mdi-cursor-default-click</v-icon></v-btn>
           
       </v-col>
       <v-col v-if="orderBox != 'plus'"  @click="startRechner('60plus')"  cols="12" md="3" class="pl-6" style="display: flex; flex-direction: column; justify-content: flex-end;cursor: pointer;">
            <span style="align-self: flex-end;" class="mb-2"><b>Zahnzusatzversicherung  60Plus</b><br>Schwerpunkt Zahnerhalt & Prothetikleistungen</span>
            <v-btn color="#513b83" class="text-white nocaps" rounded="0">Jetzt 60+-Vergleich starten <v-icon class="ml-2" style="font-size: 24px;">mdi-cursor-default-click</v-icon></v-btn>
           
       </v-col>
     </v-row>
     
  </div>
 </template>
 
 <script setup>
  import { defineProps } from "vue";

 import { useRouter } from "vue-router";
 const router = useRouter();

 const props = defineProps({
  orderBox: String
});
console.log(props.orderBox);
 function startRechner(segment) {
    switch (segment) {
      case "erwachsene":
        router.push("/vergleich/starten");
        break;
      case "kind":
        router.push("/vergleich/starten/kind");
        break;
      case "60plus":
        router.push("/vergleich/starten/60plus");
        break;
      default:
        console.error("Unbekanntes Segment:", segment);
    }
  }
 </script>
 