<template>
  <div style="min-height: 1000px;">
    <v-row>
      <v-col cols="12">
        <WuerzburgerRechner :berechnung="berechnung" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>Was bietet die Würzburger DentOptimal Zahnzusatzversicherung?</h2>
        <ul>
          <li>Bis 100% Erstattung für Zahnersatz möglich</li>
          <li>Ohne Gesundheitsprüfung abschließbar</li>
          <li>Keine Wartezeit</li>
        </ul>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>Was leistet der Tarif Würzburger DentOptimal?</h2>
        <p>
          Der Tarif Würzburger DentOptimal ist als Ergänzung zu einer bestehenden privaten Krankenversicherung abzuschließen. Er dient dazu, verbleibende Eigenanteile bei Zahnersatz-Behandlungen zu reduzieren. Je nach gewähltem Tarif-Baustein können mit dem Würzburger DentOptimal Eigenanteile vollständig reduziert werden.
        </p>
        <p>
          Die Zahnzusatzversicherung Würzburger DentOptimal leistet ausschließlich für Zahnersatz. 
          Es ist in Verbindung, ergänzend zu einer bestehenden privaten Krankenversicherung abzuschließen, welche bereits Leistungen für Zahnersatz (z.&nbsp;B. i.&nbsp;H.&nbsp;v. 75&nbsp;%) bietet.
          Leistet die private Krankenversicherung, leistet auch der Tarif DentOptimal für angeratene Zahnersatz-Behandlungen. Die Behandlung muss medizinisch notwendig sein. Vorvertraglich eingetretene Leistungsfälle sind vom Versicherungsschutz ausgeschlossen.
        </p>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col cols="12">
        <h2>Erstattungsbeispiel:</h2>
        <p>
          <strong>Leistung für Zahnersatz DentOptimal 20</strong><br>
          20&nbsp;% zusätzlich zur PKV - 1.&nbsp;-&nbsp;4.&nbsp;Jahr maximal 800&nbsp;€<br>
          Für Leistungen innerhalb der ersten vier Versicherungsjahre bestehen Leistungsbegrenzungen.
        </p>
        <p>
          Erstattung von 20&nbsp;% des Rechnungsbetrags für medizinisch notwendigen Zahnersatz. 
          Die Leistung ist nicht an den Höchstsatz (3,5-fache) der GOZ (Gebührenordnung für Zahnärzte) gebunden.
        </p>
      </v-col>
    </v-row> -->

    <v-row>
      <v-col cols="12">
        <h2>Brauchen Privatpatienten eine Zahnzusatzversicherung?</h2>
        <p>
          Um Eigenanteile einer meist kostspieligen Zahnersatzbehandlung (z.&nbsp;B. 3 Implantate = 10.000&nbsp;€) zu reduzieren, kann der Ergänzungstarif der Würzburger DentOptimal eine gute Option darstellen. Es hängt nicht zuletzt von den versicherten Leistungen sowie der Erstattungshöhe der bestehenden privaten Krankenversicherung ab, ob bzw. welcher Tarifbaustein der Würzburger DentOptimal zu wählen ist.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>Bekommen PKV-Patienten überhaupt eine Zahnzusatzversicherung?</h2>
        <p>
          Ja. Was lange nicht möglich war, wurde mit der Würzburger DentOptimal Zahnzusatzversicherung möglich. Dabei stellt die Würzburger DentOptimal einen Ergänzungstarif dar, welcher für den Bereich Zahnersatz leistet. Der Tarif Würzburger DentOptimal stellt somit auch keine Doppelversicherung dar.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>Ist eine Zahnzusatzversicherung für PKV-Vollversicherte überhaupt sinnvoll?</h2>
        <p>
          Ja. Zahnzusatzversicherungen für gesetzlich Versicherte Patienten (GKV) können schon länger eine private Zahnzusatzversicherung mit Leistungen für Zahnerhalt <strong>und</strong> Zahnersatz mit Leistungen bis zu 100&nbsp;% abschließen.
          Dem gegenüber stehen privat Krankenversicherte Patienten (PKV), welche Zahnersatz-Leistungen von bis zu lediglich 75&nbsp;% haben. Oft ist es aufgrund einer erneuten Gesundheitsprüfung für PKV-Versicherte nicht möglich, den Tarif für mehr Leistung hochzustufen oder gar die Versicherung zu wechseln. Demnach ist es nur möglich, im aktuellen PKV-Tarif zu verbleiben. Die Erstattung ist beim Würzburger DentOptimal denkbar einfach geregelt: Leistet die Erstversicherung (private Krankenversicherung), leistet auch der Würzburger DentOptimal.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>Würzburger DentOptimal - FAQ</h2>

        <h3>Für wen ist der Tarif geeignet?</h3>
        <p>
          Der Würzburger DentOptimal ist für privat Krankenversicherte geeignet, die ihren Selbstbehalt reduzieren bis sogar ganz eliminieren wollen.
        </p>

        <h3>Welche PKV-Selbstbehaltshöhen können abgesichert werden?</h3>
        <p>
          Es gibt vier Tarifbausteine / vier Selbstbehaltshöhen. Maximal können mit dem Tarif DentOptimal25 bis zu 25&nbsp;% des Rechnungsbetrages / Eigenanteils reduziert werden.
        </p>

        <h3>Wann kann der DentOptimal abgeschlossen werden?</h3>
        <p>
          Jeder privat Versicherte kann den Tarif DentOptimal jederzeit abschließen, unabhängig davon, wie lange der PKV-Tarif bereits besteht.
        </p>

        <h3>Was ist nicht versichert?</h3>
        <p>
          Bereits angeratene oder begonnene Behandlungen, fehlende oder nicht ersetzte Zähne, Behandlungen durch Ehe- oder Lebenspartner sowie Eltern und Kinder, auf Vorsatz beruhende Versicherungsfälle sind vom Versicherungsschutz ausgeschlossen.
        </p>

        <h3>Gibt es Gesundheitsfragen?</h3>
        <p>
          Der Tarif DentOptimal ist ohne eine umfangreiche Gesundheitsprüfung abzuschließen.
        </p>

        <h3>Gibt es Leistungsbegrenzungen?</h3>
        <p>
          Für Leistungen innerhalb der ersten vier Jahre bestehen Leistungsbegrenzungen. Nach Ablauf des vierten Versicherungsjahres bestehen keine Begrenzungen mehr. Leistungsbegrenzungen entfallen bei Unfall.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
const { proxy } = getCurrentInstance();
import WuerzburgerRechner from "@/components/tarif/vergleich/WuerzburgerRechner";

const berechnung = ref({
  show_ber_geschlecht: "Mann",
  show_ber_geburtstag: "1974-01-01",
  ber_pkv_zahn_erstattung: 75,
  ber_alter: 40,
});

onMounted(() => {
  proxy.$updateDocumentHead(
    "Zahnzusatzversicherung für privat krankenversicherte Personen",
    "Jetzt als Privatversicherter eine Zahnzusatzversicherun vergleichen. Mit bis zu 100% Erstattung für Zahnersatz-Leistungen erhalten. Ohne Gesundheitsprüfung, Leistung ohne Wartezeit."
  );
});
console.log(berechnung.value);
</script>
