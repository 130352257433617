import { defineStore } from "pinia";

export const useBerechnungStore = defineStore("berechnung", {
  state: () => ({
    berechnung: null,
    showBerechnungen: [],
    showPop: null,
    showPopApp: false,
    showPopChat: false,
    showPopChatButton: false,
    showPopAppType: "",
    showFooter: true,
    tarif: {
      showPopYes: false,
    },
  }),
  actions: {
    setShowPopChat() {
      this.showPopChat = true;
    },
    setShowFooter(show) {
      this.showFooter = show;
    },
    closePopChat() {
      this.showPopChat = false;
    },
    setShowPopChatButton() {
      this.showPopChatButton = true;
    },
    closePopChatButton() {
      this.showPopChatButton = false;
    },
    setShowPopApp(type) {
      this.showPopAppType = type;
      this.showPopApp = true;
    },
    closeShowPopAppTarif() {
      this.tarif = false;
      this.showPopApp = false;
    },
    setShowPopAppTarif(data, type) {
      if (data) {
        this.tarif = data;
      }
      this.showPopAppType = type;
      this.showPopApp = true;
    },
    closePopApp() {
      this.showPopAppType = "";
      this.showPopApp = false;
    },
    closePop(data) {
      if (data) {
        this.tarif = data;
        this.tarif.showPopYes = false;
      }
    },

    setShowPop(data, type) {
      if (data) {
        this.tarif = data;
        this.tarif.showPopType = type;
        this.tarif.showPopYes = true;
      } else {
        // Wenn data falsy ist, initialisieren Sie this.tarif entsprechend
        this.tarif = {
          showPopType: type,
          showPopYes: true, // oder andere sinnvolle Standardwerte
          dialogWidth: 888, // Standardbreite
          componentKeyStatus: 0, // Standardwert für die Komponentenschlüssel
        };
      }

      // Setzen der dialogWidth basierend auf dem übergebenen type
      switch (type) {
        case "dreivergleich":
        case "vergleichhightech":
        case "vergleichkinder":
        case "kleinzahn":
        case "vertragsschutz":
          this.tarif.dialogWidth = 1400;
          break;
        case "schnellvergleich":
          this.tarif.dialogWidth = 1111;
          break;
        default:
          this.tarif.dialogWidth = this.tarif.dialogWidth || 888;
      }

      // Erhöhen des componentKeyStatus, nur wenn tarif bereits definiert ist
      if (this.tarif) {
        this.tarif.componentKeyStatus += 1;
      }
    },

      setBerechnung(data) {
        this.berechnung = data;
      },
      setShowBerechnungen(data) {
        this.showBerechnungen = data;
      },

    // Neue Funktion zum Filtern von showBerechnungen nach AgeGroup
    getFilteredShowBerechnungen(ageGroup) {
      console.log('BeJs',ageGroup);
      if (this.showBerechnungen && this.showBerechnungen.length > 0) {
        return this.showBerechnungen.filter(item => item.AgeGroup == ageGroup);
      } else {
        return null; 
      }
    },

    // Beispielaufruf:
    // const filtered = this.getFilteredShowBerechnungen("1");
  },
});
