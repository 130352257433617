<template>
  <v-row>
    <v-col cols="1">
      <v-checkbox v-model="mitVersicherungSofort"></v-checkbox>
    </v-col>

    <v-col cols="8" xs="12" class="pt-6">
      <span v-if="tarif.tarif_sofort == 1">
        <h3 class="subtitle text-green">
          Sofortversicherung Ihrer laufenden Behandlung
        </h3>
        Für 29,90 € monatlich (Vertragslaufzeit 24 Monate) können Sie die Kosten
        Ihrer laufenden bzw. angeratenen Behandlung mitversichern.
      </span>
      <span v-if="tarif.tarif_sofort == 2">
        <h3 class="subtitle text-green">Sofortversicherung Implantate</h3>
        Mit dem ZahnPRIVAT Premium der Union Krankenversicherung kann eine
        bereits angeratene Implantat-Versorgung gegen einen Risikozuschlag in
        Höhe von 8,60 € je Zahn mitversichert werden.
      </span>
      <br />
      <a @click="showSofortPop = !showSofortPop"
        >Leistungsdetails der Sofort-Option anzeigen</a
      >

      <v-dialog width="888" v-model="showSofortPop">
        <v-card class="pa-4">
          <SofortPop :tarif="tarif" :berechnung="berechnung" />
        </v-card>
      </v-dialog>
    </v-col>

    <v-col cols="2" class="pl-2" hidden-xs-only></v-col>
  </v-row>
</template>

<script setup>
  import SofortPop from "@/components/tarif/info/SofortPop";
  import { ref, watch, defineProps, defineEmits } from "vue";
  const emit = defineEmits(["updatedMitversicherungSofort"]);
  const props = defineProps({
    tarif: {
      type: Object,
    },
    berechnung: {
      type: Object,
    },
  });
  const mitVersicherungSofort = ref(false);
  const showSofortPop = ref(false);

  watch(mitVersicherungSofort, (newValue) => {
    emit("updatedMitversicherungSofort", newValue); // Emit das Update Event mit dem neuen Wert
  });
  console.log(props.tarif);
</script>
