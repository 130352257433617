<template>
  <div>
   <v-row class="my-12" >
       <v-col cols="10"  @click="startRechner('erwachsene')" class="pa-6" style="background-color: #E5EDF4;border-right: 2px solid #E5EDF4;cursor: pointer;">
            <span style="align-self: flex-end;" class="mb-2"><b>Zahnzusatzversicherung  vergleichen</b><br>Schwerpunkt Zahnersatz & Zahnerhalt</span>
            <v-btn color="blue" class="text-white nocaps" rounded="0">Jetzt Vergleich starten <v-icon class="ml-2" style="font-size: 24px;">mdi-cursor-default-click</v-icon></v-btn>
       </v-col>
     </v-row>
     
  </div>
 </template>
 
 <script setup>


 import { useRouter } from "vue-router";
 const router = useRouter();



 function startRechner(segment) {
    switch (segment) {
      case "erwachsene":
        router.push("/vergleich/starten");
        break;
      case "kind":
        router.push("/vergleich/starten/kind");
        break;
      case "60plus":
        router.push("/vergleich/starten/60plus");
        break;
      default:
        console.error("Unbekanntes Segment:", segment);
    }
  }
 </script>
 