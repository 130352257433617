<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="9" style="background-color: #fafafa">
        <PersonalfragenHeader
          class="pa-4"
          v-if="berechnung"
          :berechnung="berechnung"
          :tarif="tarif"
        />
        <PersonalfragenForm
          v-if="berechnung"
          @updateAntrag="handleAntragUpdate"
          @insertZahnarzt="handleInsertZahnarzt"
          :berechnung="berechnung"
          :tarif="tarif"
        />
        <v-divider :thickness="4" color="blue" class="my-4"></v-divider>
        <KundenSchutz class="pa-4" v-if="berechnung" />
      </v-col>
      <v-col cols="12" md="3" v-if="!sharedDataStore.isMobile()">
        <PersonalfragenMenue
          v-if="berechnung"
          :berechnung="berechnung"
          :tarif="tarif"
        />
        <!-- {{ antragdata }} -->
      </v-col>
    </v-row>
  </v-container>
</template>

<!-- 1. pass antragdata to form, mach alle checks im form wenn false set FormChecked= False -> pass it back and watch it make submit only inf formcheck = true -->

<script setup>
  import { ref, getCurrentInstance, onMounted, watch } from "vue";
  import PersonalfragenForm from "@/components/berechnung/form/PersonalfragenForm";
  import PersonalfragenHeader from "@/components/berechnung/form/PersonalfragenHeader";
  import PersonalfragenMenue from "@/components/berechnung/form/PersonalfragenMenue";
  import KundenSchutz from "@/components/berechnung/form/KundenSchutz";
  import BaseCalls from "@/services/BaseCalls";
  import { useLoaderStore } from "@/stores/loaderStore";
  import { useSharedDataStore } from "@/stores/sharedData";
  import { useRoute, useRouter } from "vue-router";
  import { useBerechnungStore } from "@/stores/berechnung";
  import { useErrorStore } from "@/stores/errorStore";
  const errorStore = useErrorStore();
  const berechnungStore = useBerechnungStore();
  const sharedDataStore = useSharedDataStore();
  const { proxy } = getCurrentInstance();
  const loaderStore = useLoaderStore();
  const route = useRoute();
  const router = useRouter();
  const tarif = ref(false);
  const berechnung = ref(false);
  const componentKeyStatus = ref(0);
  const antragdata = ref(false);
  const zahnarztdata = ref(false);
  const insertStarted = ref(false);

  function setLoading(loading) {
    loaderStore.setLoader(loading);
  }
  async function getVergleich() {
    let berSelect = {};
    berSelect.action = "getTarifAntragBerechnung";
    berSelect.ber_identity_token = route.params.token;
    berSelect.tarif_id = route.params.tarif_id;
    console.log("getVergleich " + berSelect.tarif_id);
    try {
      const resp = await BaseCalls.postTarif(berSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.berechnung) {
        tarif.value = resp.data.tarif;
        berechnung.value = resp.data.berechnung;
        setLoading(false);
      } else {
        setLoading(false);
        errorStore.setError(true, "Dieser Tarif ist nicht beantragbar.");
      }
    } catch (err) {
      console.log("error berechnung");
    }
  }

  async function handleInsertZahnarzt(data) {
    zahnarztdata.value = data;
    console.log('zadata', zahnarztdata.value);
    
  }

 

  async function insertZahnarzt(aid, abs_id){
    if (!zahnarztdata.value || !zahnarztdata.value.kuza_name) {
        return;
      }
    try {
      zahnarztdata.value.kuza_aid = aid;
      zahnarztdata.value.kuza_abs_id = abs_id;
       await BaseCalls.postBerechnung(zahnarztdata.value);
} catch (err) {
      console.log("error insert");
    }
  }

  async function insertKunde() {
    console.log("startInsertKunde");
    let berSelect = {};
    berSelect.action = "insertKunde";
    berSelect.antragdata = antragdata.value;
    berSelect.tarif = tarif.value;
    berSelect.berechnung = berechnung.value;
    try {
      const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
      if (resp.data && resp.data.aid > 0) {
      
        setLoading(false);
        sendMail(1, resp.data.aid);
        if(resp.data.abs_id > 0){
          insertZahnarzt(resp.data.aid, resp.data.abs_id);
        }
        router.push(
          "/angebot/es_hat_geklappt/" +
            tarif.value.tarif_id +
            "/" +
            berechnung.value.ber_identity_token
        );
      } else {
        errorStore.setError(true, resp.data.errortext);
      }
    } catch (err) {
      console.log("error insert");
    }
  }
  async function sendMail(textId, aid) {
    let berSelect = {};
    berSelect.action = "sendEmail";
    berSelect.empfaenger = antragdata.value.email;
    berSelect.aid = aid;
    berSelect.tarif_name_wtb =
      tarif.value.un_name + " " + tarif.value.tarif_title;
    if (berechnung.value.ber_segment <= 1) {
      berSelect.text = 313;
    }
    if (berechnung.value.ber_segment == 2) {
      berSelect.text = 148;
    }
    if (berechnung.value.ber_segment == 3) {
      berSelect.text = 149;
    }

    try {
      const resp = await BaseCalls.postBerechnung(berSelect); // Korrigiert, um .value zu verwenden
      if (resp.data) {
        console.log(resp.data);
      }
    } catch (err) {
      console.log("error insert");
    }
  }
  function handleAntragUpdate(data) {
    antragdata.value = data;
    if (data.startInsert && !insertStarted.value) {
      insertStarted.value = true;
      insertKunde();
    }
  }
  onMounted(() => {
    setLoading(true);
    getVergleich();
  });

  watch(
    () => route.params.token,
    (newToken, oldToken) => {
      // Optional: Überprüfe, ob sich der Token tatsächlich geändert hat
      if (newToken !== oldToken && !antragdata.value.startInsert) {
        getVergleich(); // Rufe getVergleich auf, wenn sich der Token ändert
      }
      componentKeyStatus.value += 1; // Inkrementiere den componentKeyStatus
    },
    {}
  );
  onMounted(() => {
   
    berechnungStore.setShowFooter(false);
    proxy.$updateDocumentHead(
      "Infopaket anfordern - Schritt 2 von 2",
      "Infopaket anfordern - Schritt 2 von 2"
    );
  });
</script>

